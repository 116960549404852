<template>

	<div>
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar encuesta ..."
								trim
							/>
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refSurveyListTable"
				class="position-relative"
				:style="totalSurveys < 3 && totalSurveys > 0 ? 'height: 250px' : 'height: inherit'"
				:items="fetchSurveys"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc">

				<template #head(code)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(survey_state_name)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(code)="data">
					<b-link
						:to="{ name: 'booking-edit', params: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap">
                        {{ data.item.code}}
                    </b-link>
				</template>

				<template #cell(fullname)="data">
					<span v-if="data.item.client.names">{{ data.item.client.names }} {{ data.item.client.first_surname }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(document_number)="data">
					<span v-if="data.item.client.document_number">{{ data.item.client.document_number }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(email)="data">
					<span v-if="data.item.client.email">{{ data.item.client.email }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(score)="data">                     
					<div class="item-meta">
						<ul class="unstyled-list list-inline mb-25">
							<li
								v-for="star in 5"
								:key="star"
								class="ratings-list-item"
							>
								<feather-icon
									icon="StarIcon"
									size="18"
									class="mr-25"
									:class="[{'fill-current': star <= data.item.survey.score}, star <= data.item.survey.score ? 'text-warning' : 'text-muted']"
								/>
							</li>
						</ul>
					</div>
				</template>
								
				<template #cell(comments)="data">
					{{ data.item.survey.comments.substring(0,20) + "..." }}
				</template>

				<template #cell(survey_state_name)="data">
					<b-badge
                        pill
                        :variant="data.item.survey_state_color"
                    >
						{{ data.item.survey_state_name }}
                    </b-badge>
                </template>

				<template #cell(completion_date)="data">
                    {{ data.item.survey.completion_date ? moment(data.item.survey.completion_date).format('DD/MM/YYYY HH:mm:ss') : '-' }}
				</template>

				<template #cell(testimonial)="data">
                    <div class="text-center">
						<feather-icon
							v-if="data.item.survey.testimonial"
							class="text-success"
							icon="CheckCircleIcon"
							size="25"
						/>
						<feather-icon
							v-else
							class="text-danger"
							icon="XCircleIcon"
							size="25"
						/>
					</div>
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item
                            v-if="!data.item.survey.testimonial"
                            @click="checkTestimonial(data.item.survey.id)"
                        >
                            <feather-icon icon="CheckCircleIcon" />
                            <span class="align-middle ml-50">Marcar</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="data.item.survey.testimonial"
                            @click="uncheckTestimonial(data.item.survey.id)"
                        >
                            <feather-icon icon="XCircleIcon" />
                            <span class="align-middle ml-50">Desmarcar</span>
                        </b-dropdown-item>

						<b-dropdown-item
                            v-if="data.item.survey_state_id === 3"
							@click="openSurveyModal(data.item)"
                        >
                            <feather-icon icon="MessageSquareIcon" />
                            <span class="align-middle ml-50">Ver encuesta</span>
                        </b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalSurveys"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<BSurveyModal
			ref="bsurveymodal"
			:data="surveyData"
		/>
	</div>
</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import BSurveyModal from '@core/components/b-survey-modal/BSurveyModal.vue';
	import { useToast } from 'vue-toastification/composition';
	import { ref, onUnmounted } from '@vue/composition-api';
	import surveyStoreModule from '../surveyStoreModule';
	import useSurveysList from './useSurveysList';
	import VueSweetalert2 from 'vue-sweetalert2';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);
	
	export default {
		components: {
			BSurveyModal,			
			vSelect
		},
		data() {                              
            return {
                moment
            }
        },
		setup() {

			// Use toast
			const toast = useToast();

			// REFS
			const bsurveymodal = ref(null);
			const surveyData = ref({});

			const SURVEY_APP_STORE_MODULE_NAME = 'app-survey';

			// Register module
			if (!store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.registerModule(SURVEY_APP_STORE_MODULE_NAME, surveyStoreModule);

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.unregisterModule(SURVEY_APP_STORE_MODULE_NAME);
			});

			const checkTestimonial = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de marcarlo como testimonio?',
					text: "¡Si no lo está, puede cancelar la acción!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, marcar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						store.dispatch('app-survey/checkTestimonial', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al marcarlo como testimonio.',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};

			const uncheckTestimonial = (id) => {

				Vue.swal({
					title: '¿Está seguro(a) de desmarcarlo como testimonio?',
					text: "¡Si no lo está, puede cancelar la acción!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, desmarcar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						store.dispatch('app-survey/uncheckTestimonial', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al desmarcarlo como testimonio.',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};

			const openSurveyModal = (data) => {
				const item = {
					completion_date: data.survey.completion_date ? moment(data.survey.completion_date).format('DD/MM/YYYY HH:mm:ss') : '-',
					user: (data.client.names ? `${ data.client.names } ${ data.client.first_surname }` : '-'),
					score: data.survey.score,
					comments: data.survey.comments
				}

				surveyData.value = item;

				bsurveymodal.value.$refs.modal.show();
			};

			const {
				fetchSurveys,
				tableColumns,
				perPage,
				currentPage,
				totalSurveys,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refSurveyListTable,
				refetchData
			} = useSurveysList();
			
			return {
				fetchSurveys,
				tableColumns,
				perPage,
				currentPage,
				totalSurveys,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refSurveyListTable,
				refetchData,

				// REFS
				bsurveymodal,
				surveyData,
				
				// Filter
				checkTestimonial,
				uncheckTestimonial,
				openSurveyModal
			}
		}
	}

</script>

<style lang="scss" scoped>

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>