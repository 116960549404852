import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useSurveysList() {

    // Use toast
    const toast = useToast();

    const refSurveyListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'code', sortable: true, label: 'CÓDIGO </br> RESERVA' },
        { key: 'fullname', sortable: true, label: 'USUARIO' },
        { key: 'document_number', sortable: true, label: 'DNI' },
        { key: 'email', sortable: true, label: 'CORREO' },
        { key: 'client.phone', sortable: true, label: 'CELULAR' },
        { key: 'score', sortable: true, label: 'PUNTAJE' },
        { key: 'comments', sortable: true, label: 'COMENTARIO' },
        { key: 'survey_state_name', sortable: true, label: 'ESTADO </br> ENCUESTA' },
        { key: 'completion_date', sortable: true, label: 'Fecha registro' },
        { key: 'testimonial', sortable: true, label: 'Testimonio' },
        { key: 'actions', sortable: false, label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalSurveys = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('completion_date');
    const isSortDirDesc = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refSurveyListTable.value ? refSurveyListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalSurveys.value,
        }
    });

    const refetchData = () => {
        refSurveyListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchSurveys = (ctx, callback) => {
        store
            .dispatch('app-survey/fetchSurveys', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value
            })
            .then(response => {
                const { surveys, total } = response.data;
                callback(surveys);
                totalSurveys.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de encuestas',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchSurveys,
        tableColumns,
        perPage,
        currentPage,
        totalSurveys,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refSurveyListTable,

        refetchData
    }
}